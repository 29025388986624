'use client';

import RawHtml from '@components/RawHtml';
import PinIcon from '@icons/pin.svg';
import OnlineCourses from './OnlineCourses';
import OfflineCourses from './OfflineCourses';
import { useState } from 'react';
import RegistrationSuccessful from './RegistrationSuccessful';

export type Kurs = {
  _oid: string;
  titel: string;
  ausbildungsort: {
    name: string | null;
    ort: string;
  };
  oid_ktyp: string;
  ktyp: {
    titel: string;
    titellang: string | null;
  } | null;
  von: string | null;
  bis: string | null;
  onlinesichtbarvon: string | null;
  onlinesichtbarbis: string | null;
  onlineverfuegbar: boolean;
  fortlaufendereinstieg: boolean;
  teilnehmerhinweise: string | null;
  vollzeitteilzeit: string | null;
};

export type Product = {
  type: 'course';
  value: {
    title: string;
    description: string;
    kurs: string;
    number_dates_to_emit: number;
    attendance_type: 'ONLINE' | 'LIVE' | 'ONSITE';
    booking: 'DIRECT' | 'INFO';
  };
  id: string;
};

type Props = {
  title: string;
  text: string;
  products: Product[];
  pseudo_product: string;
};

function EventFinder(props: Props) {
  const { title, text, products, pseudo_product } = props;
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);

  const onlyOnline = products.every(product => product.value.attendance_type === 'LIVE');

  if (registrationSuccessful) {
    return <RegistrationSuccessful />;
  }

  return (
    <div className='streamfield event-finder'>
      <div className='event-finder__icon'>
        <PinIcon />
      </div>
      <h2 className='event-finder__title'>{title}</h2>
      {text ? <RawHtml html={text} className='event-finder__description' /> : null}
      {/* <p className='event-finder__text'>Termine und Infoveranstaltungen</p> */}
      {onlyOnline ? (
        <OnlineCourses
          products={products}
          pseudoProduct={pseudo_product}
          onSuccess={() => setRegistrationSuccessful(true)}
        />
      ) : null}
      {!onlyOnline ? (
        <OfflineCourses
          products={products}
          pseudoProduct={pseudo_product}
          onSuccess={() => setRegistrationSuccessful(true)}
        />
      ) : null}
    </div>
  );
}

export default EventFinder;
