import { WagtailImage } from '@/types';
import Image from '@components/Image';
import clsx from 'clsx';

type Props = {
  title: string;
  productType?: string;
  image?: WagtailImage | null;
};

function Hero(props: Props) {
  const { title, image, productType } = props;

  return (
    <div role='banner' className='hero full-width'>
      {image ? (
        <Image
          className='hero__image full-width'
          image={image}
          width={1920}
          height={500}
          spec='fill-1920x500'
          priority
          sizes='100vw'
        />
      ) : null}
      {productType ? <div className='hero__product-type'>{productType}</div> : null}
      <h1 className={clsx('hero__title', { 'hero__title--has-type': productType })}>{title}</h1>
    </div>
  );
}

export default Hero;
