import React from 'react';
import CheckboxConfirmCircleIcon from '@icons/checkbox-confirm-circle.svg';

function RegistrationSuccessful() {
  return (
    <div className='streamfield event-finder'>
      <div className='event-finder__icon event-finder__icon--secondary'>
        <CheckboxConfirmCircleIcon />
      </div>
      <h2 className='event-finder__title'>Vielen Dank!</h2>
      <p className='event-finder__description'>
        <strong>Ihre Anmeldung war erfolgreich.</strong>
      </p>
      <p className='event-finder__description'>
        Vielen Dank für Ihr Interesse an unserem Kursangebot. Sie erhalten von uns an die von Ihnen
        angegebene E-Mail-Adresse weitere Informationen zugesendet.
      </p>
    </div>
  );
}

export default RegistrationSuccessful;
