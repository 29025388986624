/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

type Props = {
  title: string;
  file: string;
  type: 'video' | 'audio';
  width: number | null;
  height: number | null;
  thumbnail: string | null;
};

function Media(props: Props) {
  const { title, file, type, thumbnail, width, height } = props;

  return (
    <div className='streamfield video'>
      {type === 'video' ? (
        <video
          preload='none'
          width={width ?? '100%'}
          height={height ?? 'auto'}
          controls
          poster={thumbnail ?? ''}
        >
          <source src={file} />
        </video>
      ) : null}
      {type === 'audio' ? (
        <>
          {title}
          <audio preload='none' controls>
            <source src={file} />
          </audio>
        </>
      ) : null}
    </div>
  );
}

export default Media;
