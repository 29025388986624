import { WagtailPage } from '@/types';
import RawHtml from '@components/RawHtml';
import { removeBaseUrl } from '@utils/url';
import Link from 'next/link';
import React, { PropsWithChildren } from 'react';

type WrapperProps = {
  linkIntern?: WagtailPage;
  linkExtern?: string;
};

function Wrapper(props: PropsWithChildren<WrapperProps>) {
  const { children, linkIntern, linkExtern } = props;

  if (linkIntern) {
    return (
      <Link href={removeBaseUrl(linkIntern.full_url)} passHref className='eye-catcher__link'>
        {children}
      </Link>
    );
  }

  if (linkExtern) {
    return (
      <Link href={linkExtern} passHref className='eye-catcher__link'>
        {children}
      </Link>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

type Props = {
  text: string;
  link_intern?: WagtailPage;
  link_extern?: string;
};

function EyeCatcher(props: Props) {
  const { text, link_extern: linkExtern, link_intern: linkIntern } = props;

  return (
    <div className='eye-catcher'>
      <Wrapper linkIntern={linkIntern} linkExtern={linkExtern}>
        <RawHtml className='eye-catcher__text' html={text} />
      </Wrapper>
    </div>
  );
}

export default EyeCatcher;
