import Button from '@components/Button';
import RawHtml from '@components/RawHtml';
import { useRouter } from 'next/router';

import CheckboxConfirmIcon from '@icons/checkbox-confirm-circle.svg';
import EditTextIcon from '@icons/edit-text.svg';
import Link from 'next/link';

type Props = {
  title: string;
  text: string;
  next: () => void;
  isEnd?: boolean;
  isSuccess?: boolean;
  contactForm: string;
};

function Notice(props: Props) {
  const { title, text, next, isEnd, isSuccess, contactForm } = props;
  const router = useRouter();

  return (
    <div className='notice'>
      <div className='questionaire__icon'>{isEnd ? <CheckboxConfirmIcon /> : <EditTextIcon />}</div>
      <h2 className='questionaire__title'>{title}</h2>
      <RawHtml html={text} className='questionaire__text' />
      {!isEnd ? (
        <Button primary onClick={next}>
          Weiter
        </Button>
      ) : null}
      {isEnd && !isSuccess ? (
        <Button primary onClick={() => router.push('/')}>
          Zurück zur Startseite
        </Button>
      ) : null}
      {isEnd && isSuccess ? (
        <Link href={contactForm} className='notice__form-link'>
          Zum Formular
        </Link>
      ) : null}
    </div>
  );
}

export default Notice;
