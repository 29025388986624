import Button from '@components/Button';
import { RadioGroup } from '@components/Form';
import EditTextIcon from '@icons/edit-text.svg';

type TQuestion = {
  text: string;
  yes: string;
  no: string;
};

type Props = {
  title: string;
  text: string;
  next: (answer: keyof TQuestion) => void;
  prev: () => void;
};

function Question(props: Props) {
  const { title, text, next, prev } = props;

  return (
    <div className='question'>
      <div className='questionaire__icon'>
        <EditTextIcon />
      </div>
      <h2 className='questionaire__title'>{title}</h2>
      <form
        onSubmit={e => {
          e.preventDefault();

          const formData = new FormData(e.currentTarget);
          const answer = formData.get('answer');

          if (answer) {
            next(answer as keyof TQuestion);
          }
        }}
      >
        <RadioGroup
          items={[
            { label: 'Ja', value: 'yes' },
            { label: 'Nein', value: 'no' },
          ]}
          label={text}
          id='answer'
          row
          centered
          required
        />
        <div className='question__buttons'>
          <Button type='button' onClick={() => prev()}>
            Zurück
          </Button>
          <Button primary type='submit'>
            Weiter
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Question;
