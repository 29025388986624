import Link from 'next/link';
import React from 'react';
import Image from '@components/Image';
import { addBaseUrl } from '@/utils/url';
import { WagtailPage, WagtailImage } from '@/types';

type LinkTile = {
  title: string;
  button_page: WagtailPage | null;
  button_url: string | null;
  image: WagtailImage | null;
};

type Props = {
  link_kacheln: LinkTile[];
};

function Links(props: Props) {
  const { link_kacheln: linkKacheln } = props;

  return (
    <div className='streamfield links'>
      {linkKacheln.map(tile => (
        <Link
          key={tile.title}
          // href={tile.button_page ? removeBaseUrl(tile.button_page.full_url) : tile.button_url ?? ''}
          href='/'
          passHref
          className='links__tile'
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

          <div className='title'>{tile.title}</div>
          {tile.image ? (
            <Image
              image={tile.image}
              className='icon'
              width={78}
              height={78}
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          ) : null}
        </Link>
      ))}
    </div>
  );
}

export default Links;
