import Rating from '@streamfields/Rating';
import Accordions from '@streamfields/Accordions';
import Buttons from '@streamfields/Buttons';
import CourseInformation from '@streamfields/CourseInformation';
import CTA from '@streamfields/CTA';
import Image from '@streamfields/Image';
import KursTypField from '@streamfields/KursTypField';
import Links from '@streamfields/Links';
import Message from '@streamfields/Message';
import Richtext from '@streamfields/Richtext';
import Table from '@streamfields/Table';
import TextBlockSnippet from '@streamfields/TextBlockSnippet';
import EyeCatcher from '@streamfields/EyeCatcher';
import Reservation from '@streamfields/Reservation';
import TargetGroup from '@streamfields/TargetGroup';

import EventFinder from './EventFinder';
import JobQuestionaire from './JobQuestionaire';
import Video from './Video';
import Media from './Media';
import Products from './Products';
import CourseSummary from './CourseSummary';
import Columns from './Columns';
import Container from './Container';
import Downloads from './Downloads';
import CourseHeader from './CourseHeader';
import Contacts from './Contacts';

const streamfields = {
  accordion: Accordions,
  buttons: Buttons,
  code: Richtext,
  courseinformation: CourseInformation,
  cta: CTA,
  eyecatcher: EyeCatcher,
  image: Image,
  abes_field: KursTypField,
  links: Links,
  media: Media,
  message: Message,
  products: Products,
  full_richtext: Richtext,
  rating: Rating,
  reservation: Reservation,
  simple_richtext: Richtext,
  table: Table,
  targetgroup: TargetGroup,
  textblocksnippet: TextBlockSnippet,
  video: Video,
  job_questionaire: JobQuestionaire,
  event_finder: EventFinder,
  course_summary: CourseSummary,
  columns: Columns,
  container: Container,
  downloads: Downloads,
  course_header: CourseHeader,
  contacts: Contacts,
};

export type Streamfields = keyof typeof streamfields;

export default streamfields;
