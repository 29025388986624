import React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import * as LabelPrimitive from '@radix-ui/react-label';
import Fieldset from '@components/Form/Fieldset';
import clsx from 'clsx';

type RadioButtonItemData = {
  label: string;
  value?: string;
};

type Props = {
  id: string;
  items: RadioButtonItemData[];
  label: string;
  row?: boolean;
  centered?: boolean;
  required?: boolean;
};

function RadioGroup(props: Props) {
  const { id, items, label, row, centered, required } = props;

  return (
    <Fieldset className={clsx('radio-group')} centered={centered} legend={label} asLabel>
      <RadioGroupPrimitive.Root
        className={clsx('radio-group__root', {
          'radio-group__root--row': row,
          'radio-group__root--centered': centered,
        })}
        name={id}
        required={required}
      >
        {items.map((item, index) => (
          <div className='radio-group__item-container' key={`${id}-${item.value ?? item.label}`}>
            <RadioGroupPrimitive.Item
              className='radio-group__item'
              value={item.value ?? item.label}
              id={`${id}-${index}`}
              required={required}
            >
              <RadioGroupPrimitive.Indicator className='radio-group__indicator' />
            </RadioGroupPrimitive.Item>
            <LabelPrimitive.Root className='radio-group__label' htmlFor={`${id}-${index}`} asChild>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>{item.label}</label>
            </LabelPrimitive.Root>
          </div>
        ))}
      </RadioGroupPrimitive.Root>
    </Fieldset>
  );
}

export default RadioGroup;
