import Link from 'next/link';

import { WagtailImage } from '@/types';
import { removeBaseUrl } from '@/utils/url';
import Image from '@components/Image';
import RawHtml from '@components/RawHtml';

type Props = {
  text: string;
  image: WagtailImage;
  button_page: { title: string; full_url: string } | null;
  button_url: string | null;
  button_text: string;
};

function CTA(props: Props) {
  const {
    button_page: buttonPage,
    button_url: buttonUrl,
    button_text: buttonText,
    image,
    text,
  } = props;

  return (
    <div className='streamfield cta'>
      <div className='cta__image'>
        <Image
          image={image}
          sizes='(min-width: 65em) 50vw, 100vw'
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </div>
      <RawHtml html={text} className='cta__text' />
      {buttonPage ? (
        <Link
          href={removeBaseUrl(buttonPage.full_url)}
          passHref
          className='cta__link  button button--primary'
        >
          {buttonText}
        </Link>
      ) : null}
      {buttonUrl ? (
        <Link href={buttonUrl} passHref className='cta__link button button--primary'>
          {buttonText}
        </Link>
      ) : null}
    </div>
  );
}

export default CTA;
