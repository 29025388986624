import { ParsedUrlQuery } from 'querystring';

class UrlNotSetProperlyError extends Error {}

const baseUrl = process.env.WAGTAIL_BASE_URL ?? process.env.NEXT_PUBLIC_WAGTAIL_BASE_URL;

function removeBaseUrl(url: string) {
  if (url === null) {
    throw new UrlNotSetProperlyError(
      'URL ist null! Did you copy pages from one root to antoher? Please check if all links in the copied page are updated properly.',
    );
  }
  return url.replace('https://sbh-schablone.werkbank.de', '').replace(baseUrl, '') ?? '';
}

function addBaseUrl(url: string) {
  return `${baseUrl}${url}`;
}

function toQuerystring(query: ParsedUrlQuery) {
  return Object.entries(query)
    .filter(Boolean)
    .map(entry => entry.join('='))
    .join('&');
}

export { addBaseUrl, removeBaseUrl, toQuerystring };
