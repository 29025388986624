import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

type Props = {
  legend: string;
  className?: string;
  asLabel?: boolean;
  centered?: boolean;
};

function Fieldset(props: PropsWithChildren<Props>) {
  const { children, legend, className, asLabel, centered } = props;

  return (
    <fieldset
      className={clsx('fieldset', className, {
        'fieldset--as-label': asLabel,
        'fieldset--centered': centered,
      })}
    >
      <legend className='fieldset__legend'>{legend}</legend>
      <div className='fieldset__fields-container'>
        {children
          ? React.Children.map(children, child => (
              <div
                className={clsx(
                  // @ts-ignore
                  { 'form__field--full-width': child?.props.full_width },
                  // @ts-ignore
                  { 'form__field--checkbox': child?.props.field_type === 'checkbox' },
                )}
              >
                {child}
              </div>
            ))
          : null}
      </div>
    </fieldset>
  );
}

export default Fieldset;
