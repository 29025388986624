export type TNotice = {
  title: string;
  text: string;
  next: string | null;
};

export type TQuestion = {
  text: string;
  yes: string;
  no: string;
};

export type TQuestionaire = { [key: string]: TQuestion | TNotice };

const questions: TQuestionaire = {
  question1: {
    text: 'Bist du älter als 20 Jahre?',
    yes: 'question2',
    no: 'notice1',
  },
  notice1: {
    title: 'Hinweis',
    text: 'Hinweis, dass die Weiterbildung erst ab einem Alter von 20 möglich ist.',
    next: null,
  },
  question2: {
    text: 'Erfolgreicher Schulabschluss mind. Klasse 9?',
    yes: 'question3',
    no: 'notice2',
  },
  notice2: {
    title: 'Hinweis',
    text: 'Hinweis, dass der Abschluss zuerst nachgeholt werden muss für die Weiterbildung.',
    next: null,
  },
  question3: {
    text: 'Wurde der Abschluss in Deutschland erworben?',
    yes: 'question6',
    no: 'question4',
  },
  question4: {
    text: 'Liegt eine Übersetzung des Zeugnisses mit Anerkennung vor?',
    yes: 'question5',
    no: 'notice4',
  },
  notice4: {
    title: 'Hinweis',
    text: 'Hinweise, dass der Abschluss mit Anerkennung erst beigebracht werden muss.',
    next: 'end',
  },
  question5: {
    text: 'Verfügst du über ein B1 Sprachniveau gem. EU Referenzrahmen?',
    yes: 'question6',
    no: 'notice5',
  },
  notice5: {
    title: 'Hinweis',
    text: 'Hinweis, dass das Sprachniveau bei der SBH erworben werden kann.',
    next: 'question6',
  },
  question6: {
    text: 'Hast du im Register in Flensburg weniger als 5 Punkte und noch nie einen Führerschein abgegeben?',
    yes: 'notice6',
    no: 'question7',
  },
  notice6: {
    title: 'Hinweis',
    text: 'Die muss leider vor einer Weiterbildung noch geprüft werden.',
    next: 'question7',
  },
  question7: {
    text: 'Fühlst du dich körperlich fit?',
    yes: 'question8',
    no: 'notice7',
  },
  notice7: {
    title: 'Hinweis',
    text: 'Dies muss leider vor einer Weiterbildung noch von uns geprüft werden.',
    next: 'question8',
  },
  question8: {
    text: 'Arbeitest du gerne in Uniform?',
    yes: 'question9',
    no: 'question10',
  },
  question9: {
    text: 'Bist Du reiselustig und gerne in ganz DE unterwegs?',
    yes: 'notice9-1',
    no: 'notice9-2',
  },
  'notice9-1': {
    title: 'Hinweis',
    text: 'Glückwunsch! Du scheinst geeignet für den Fernverkehr Gütertransport.',
    next: 'success',
  },
  'notice9-2': {
    title: 'Hinweis',
    text: 'Glückwunsch! Du scheinst geeignet für Güternahverkehr.',
    next: 'notice11',
  },
  question10: {
    text: 'Bist Du reiselustig und gerne in ganz DE unterwegs?',
    yes: 'notice10-1',
    no: 'notice10-2',
  },
  'notice10-1': {
    title: 'Hinweis',
    text: 'Glückwunsch! Du erfüllst die Voraussetzungen für den Fernverkehr Personentransport.',
    next: 'success',
  },
  'notice10-2': {
    title: 'Hinweis',
    text: 'Glückwunsch! Du scheinst geeignet für den ÖPNV.',
    next: 'success',
  },
  end: {
    title: 'Hinweis',
    text: 'Nimm Kontakt zu uns auf!',
    next: null,
  },
  success: {
    title: 'Hinweis',
    text: 'Nimm Kontakt zu uns auf!',
    next: null,
  },
};

export default questions;
