'use client';

import { WagtailImage } from '@/types';
import RawHtml from '@components/RawHtml';
import { addBaseUrl, removeBaseUrl } from '@utils/url';
import Image from 'next/image';
import Link from 'next/link';
import { useId } from 'react';
import { MdChevronRight } from 'react-icons/md';

import * as AccordionPrimitive from '@radix-ui/react-accordion';

type Course = {
  title: string;
  full_url: string;
};

type Props = {
  title: string;
  image: WagtailImage;
  description: string;
  courses: Course[];
};

function TargetGroup(props: Props) {
  const { title, image, description, courses } = props;

  const id = useId();

  return (
    <AccordionPrimitive.Root type='multiple' className='streamfield target-group' id={id}>
      <AccordionPrimitive.Item
        value={title}
        className='target-group__item'
        onClick={e => {
          if (e.currentTarget.dataset.state === 'closed') {
            e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }}
      >
        <AccordionPrimitive.Header asChild>
          <div className='target-group__header'>
            <AccordionPrimitive.Trigger className='target-group__trigger'>
              <div className='target-group__title-container'>
                <h2 className='target-group__title'>
                  {title}
                  <MdChevronRight
                    fill='var(--clr-primary)'
                    className='target-group__chevron'
                    size='2em'
                    aria-hidden
                  />
                </h2>
                <RawHtml className='target-group__title-text' html={description} />
              </div>
              <Image
                src={addBaseUrl(image.filename)}
                alt=''
                fill
                sizes='100vw'
                style={{
                  objectFit: 'cover',
                }}
              />
            </AccordionPrimitive.Trigger>
          </div>
        </AccordionPrimitive.Header>
        <AccordionPrimitive.AccordionContent className='target-group__content'>
          <h3>Passende Kurse</h3>
          <ul>
            {courses.filter(Boolean).map(course => (
              <li key={course.title}>
                <Link href={removeBaseUrl(course.full_url)} passHref className='target-group__link'>
                  {course.title}
                </Link>
              </li>
            ))}
          </ul>
        </AccordionPrimitive.AccordionContent>
      </AccordionPrimitive.Item>
    </AccordionPrimitive.Root>
  );
}

export default TargetGroup;
