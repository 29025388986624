import React from 'react';
import { WagtailVideo } from '@/types';

type Props = {
  video: WagtailVideo;
};

function Video(props: Props) {
  const { video } = props;

  return null;

  if (!video) return null;

  // return (
  //   <div className='streamfield video'>
  //     {/* eslint-disable jsx-a11y/media-has-caption */}
  //     <video preload='none' width='100%' controls poster={video.thumbnail ?? ''}>
  //       <source
  //         src={video.}
  //         // type={`video/${video.file.split('.')[1] ?? 'mp4'}`}
  //       />
  //     </video>
  //   </div>
  // );
}

export default Video;
