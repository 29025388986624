import { Streamfield } from '@/types';
import Streamfields from '@streamfields/Streamfields';
import React from 'react';
import clsx from 'clsx';

type Props = {
  full_width: boolean;
  container_items: Streamfield[];
};

function Container(props: Props) {
  const { full_width: fullWidth, container_items: containerItems } = props;
  return (
    <div
      className={clsx('streamfield container-section content-grid', {
        'full-width': fullWidth,
      })}
    >
      <Streamfields fields={containerItems} />
    </div>
  );
}

export default Container;
