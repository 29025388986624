import React from 'react';
import streamfields from '@/streamfields';

import type { Streamfield } from '@/types';

type Props = {
  fields: Streamfield[];
  loaderData?: { [key: string]: unknown };
};

function Streamfields(props: Props) {
  const { fields, loaderData } = props;

  if (!fields?.length) return null;

  return (
    <>
      {fields.map(field => {
        const Component = streamfields[field.type];
        const loadedData = loaderData?.[field.id];
        if (!Component) {
          return (
            <h2 key={field.id} className='container'>
              Component <strong>{field.type}</strong> not found
            </h2>
          );
        }

        return (
          <Component
            key={field.id}
            loaderData={loadedData}
            {...(typeof field.value === 'string' || Number.isFinite(field.value)
              ? { value: field.value }
              : field.value)}
          />
        );
      })}
    </>
  );
}

export default Streamfields;
