import { getImageProps } from 'next/image';
import { RenditionProps } from './types';
import { guessImageDimensions, renditionUrl } from './utils';

/**
 * Component for Image Renditions which creates Source Sets.
 *
 * See Image component.
 */
function Rendition(props: RenditionProps) {
  const { image, mediaQuery, spec, width, height, fill, sizes } = props;

  const dimensions = guessImageDimensions(image, spec, width, height, fill);
  const url = renditionUrl(image, spec);

  const {
    props: { srcSet },
  } = getImageProps({
    src: url,
    fill,
    width: dimensions.width,
    height: dimensions.height,
    alt: image.alt_text,
    sizes,
  });

  return (
    <source
      key={mediaQuery}
      media={mediaQuery}
      srcSet={srcSet ?? url} // getImgProps returns no srcSet for svg files
      width={dimensions.width}
      height={dimensions.height}
      sizes={sizes}
    />
  );
}

export default Rendition;
