'use client';

import { useState } from 'react';
import EditTextIcon from '@icons/edit-text.svg';

import Button from '@components/Button';
import RawHtml from '@components/RawHtml';
import { WagtailPage } from '@/types';
import { removeBaseUrl } from '@utils/url';
import Notice from './Notice';
import Question from './Question';
import questions, { TNotice, TQuestion } from './questions';

function isQuestion(entry: TQuestion | TNotice): entry is TQuestion {
  return Boolean((entry as TQuestion)?.yes);
}

function isNotice(entry: TQuestion | TNotice): entry is TNotice {
  return Boolean((entry as TNotice)?.title);
}

type Props = { title: string; start_button_text: string; text: string; contact_form: WagtailPage };

function JobQuestinaire(props: Props) {
  const { title, start_button_text: buttonText, text, contact_form: contactForm } = props;

  const [active, setActive] = useState<string | null>(null);
  const [history, setHistory] = useState<string[]>([]);

  if (!active) {
    return (
      <div className='streamfield questionaire'>
        <div className='questionaire__icon'>
          <EditTextIcon />
        </div>
        <h2 className='questionaire__title'>{title}</h2>
        <RawHtml html={text} className='questionaire__text' />
        <Button primary onClick={() => setActive('question1')}>
          {buttonText}
        </Button>
      </div>
    );
  }

  const entry = questions[active];

  if (isQuestion(entry)) {
    return (
      <div className='streamfield questionaire'>
        <Question
          title={`Frage ${history.length + 1}`}
          text={entry.text}
          next={answer => {
            setHistory(prev => [...prev, active]);
            setActive(entry[answer]);
          }}
          prev={() => {
            const lastQuestion = history.at(-1);

            if (lastQuestion) {
              setHistory(prev => prev.slice(0, -1));
              setActive(lastQuestion);
            }
          }}
          key={entry.text}
        />
      </div>
    );
  }

  if (isNotice(entry)) {
    return (
      <div className='streamfield questionaire'>
        <Notice
          title={entry.title}
          text={entry.text}
          next={() => setActive(entry.next)}
          key={entry.text}
          isEnd={Boolean(!entry.next)}
          isSuccess={active === 'success'}
          contactForm={removeBaseUrl(contactForm.full_url)}
        />
      </div>
    );
  }

  return (
    <div className='streamfield'>
      <p>Unknonw question type</p>
    </div>
  );
}

export default JobQuestinaire;
