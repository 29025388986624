import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  id: string;
  label: string | ReactNode;
  value: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  baseline?: boolean;
  smallLabel?: boolean;
  error?: string;
};

function Checkbox(props: Props) {
  const { id, label, name, value, disabled, required, baseline, smallLabel, error } = props;

  return (
    <>
      <div
        className={clsx('checkbox', { 'checkbox--baseline': baseline, 'checkbox--error': error })}
      >
        <CheckboxPrimitive.Root
          className='checkbox__root'
          id={id}
          name={name ?? id}
          value={value}
          disabled={disabled}
          required={required}
        >
          <CheckboxPrimitive.Indicator className='checkbox__indicator'>
            <CheckIcon width={24} height={24} />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        <label
          className={clsx('checkbox__label', {
            'checkobx__label--small': smallLabel,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
      {error ? (
        <span className='checkbox__error-message'>Dieses Feld ist ein Pflichtfeld.</span>
      ) : null}
    </>
  );
}

export default Checkbox;
