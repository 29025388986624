import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import Label from '../Label';

type Props = { label: string; id: string; error?: string };

const Input = React.forwardRef<HTMLInputElement, Props & InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const { label, error, ...rest } = props;
    return (
      <div className={clsx('input', { 'input--error': error })}>
        <Label htmlFor={rest.id} text={`${label}${!rest.required ? ' (optional)' : ''}`} />
        <input ref={ref} {...rest} name={rest.name ?? rest.id} className='input__element' />
        {error ? (
          <span className='input__error-message'>Dieses Feld ist ein Pflichtfeld.</span>
        ) : null}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
