import { addBaseUrl } from '@/utils/url';

import { createHmac } from 'crypto';

import { WagtailImage } from '@/types';

import { filterSpec } from './types';

import { FilterParsingError } from './errors';

/**
 * Create image signature for wagtail rendition urls.
 *
 * Imitates `generate_signature` from `wagtail.images.utils` and has to
 * create identical signatures.
 *
 * @returns Signature of image rendition to use in rendition url
 */
function generateSignature(imageId: Number, spec: filterSpec) {
  const utf8EncodeText = new TextEncoder();
  const url = `${imageId}/${spec}/`;
  return (
    btoa(
      createHmac(
        'sha1',
        utf8EncodeText.encode(
          process.env.DJANGO_SECRET_KEY ??
            process.env.NEXT_PUBLIC_DJANGO_SECRET_KEY ??
            '<your secret>',
        ),
      )
        .update(url)
        // unfortunately digest("base64url") dose something(?) slightly
        // different than python's url safe base64 encoding. Therefore,
        // the url safe part is done by hand.
        .digest('binary'),
    )
      // Make url safe with common replacements.
      // See https://developer.mozilla.org/en-US/docs/Glossary/Base64
      .replaceAll('+', '-')
      .replaceAll('/', '_')
  );
}

/**
 * Create signed wagtail rendition urls.
 *
 * The returned url must be identical with the one that `generate_image_url`
 * from `wagtail.images.views.serve` generates.
 *
 * @returns Absolute signed url for given image rendition to fetch from wagtail.
 */
function renditionUrl(image: WagtailImage, spec: filterSpec = 'original') {
  const signature = generateSignature(image.id, spec);
  return addBaseUrl(`/images/${signature}/${image.id}/${spec}/${image.filename}`);
}

/**
 * Parse rendition dimensions from spec.
 *
 * This is not really accurate, since it is not guaranteed,
 * that the rendition will actually have these dimensions (eg. wagtail does
 * not scale images up, if they're to small).
 *
 * All available specs are listed in the wagtail docs [0]
 *
 * `fill-`, `width-` and `height-`, `scale-`, `original` filters guarantee (if
 * the image is large enough) the dimensions and are therefore evaluated.
 *
 * [0] https://docs.wagtail.org/en/stable/topics/images.html#available-resizing-methods
 *
 * @param spec
 * @returns
 */
function parseSpec(image: WagtailImage, spec: filterSpec) {
  // eg. width-400
  let re = /^width-([0-9]+)$/;
  let match = re.exec(spec);
  if (match) {
    return {
      width: Number(match[1]),
    };
  }

  // eg. height-400
  re = /^height-([0-9]+)$/;
  match = re.exec(spec);
  if (match) {
    return {
      height: Number(match[1]),
    };
  }

  // eg. fill-400x300
  re = /^fill-([0-9]+)x([0-9]+)$/;
  match = re.exec(spec);
  if (match) {
    return {
      width: Number(match[1]),
      height: Number(match[2]),
    };
  }

  // eg. scale-50
  re = /^scale-([0-9]+)$/;
  match = re.exec(spec);
  if (match) {
    const ratio = Number(match[1]) / 100;
    return {
      width: Math.round(image.width * ratio),
      height: Math.round(image.height * ratio),
    };
  }

  // original
  if (spec === 'original') {
    return {
      width: image.width,
      height: image.height,
    };
  }

  const allowedFilterPattern = /^[A-Za-z0-9_\-.]+$/;
  if (!allowedFilterPattern.exec(spec)) {
    throw new FilterParsingError(`Filter spec ${spec} is invalid!`);
  }

  return {};
}

/**
 * Guess dimensions using multiple hints.
 *
 * @param image The image containing information about it's original width and
 * height.
 * @param spec Wagtail filter spec for renditions which will be used to guess
 *  widths. See parseSpec().
 * @param width Enforces specific width
 * @param height Enforces specific height
 * @param fill No width or height will be returned in this case
 
* @returns The width/heights are evaluated with the following priorities:
 *  explicit width/height > fill > spec > image.width/height
 */
function guessImageDimensions(
  image: WagtailImage,
  spec?: filterSpec,
  userDefinedWidth?: number,
  userDefinedHeight?: number,
  fill?: boolean,
) {
  if (fill) {
    return {};
  }
  let width;
  let height;

  if (spec) {
    const parsedSizes = parseSpec(image, spec);
    width = userDefinedWidth || parsedSizes.width;
    height = userDefinedHeight || parsedSizes.height;
  } else {
    width = userDefinedWidth || image.width;
    height = userDefinedHeight || image.height;
  }

  return { width, height };
}

export { guessImageDimensions, renditionUrl };
