import { WagtailImage } from '@/types';
import Image from '@components/Image';
import RawHtml from '@components/RawHtml';
import React from 'react';

type Contact = {
  text: string;
  image: WagtailImage;
};

type Props = Record<string, Contact>;

function Contacts(props: Props) {
  const contacts = Object.values(props);

  return (
    <div className='streamfield contacts'>
      {contacts.length
        ? contacts.filter(Boolean).map(contact => (
            <div className='contacts__person' key={contact.text}>
              <Image image={contact.image} spec='fill-250x250' className='contacts__image' />
              <RawHtml html={contact.text} className='contacts__text' />
            </div>
          ))
        : null}
    </div>
  );
}

export default Contacts;
