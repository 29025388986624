import { WagtailPage } from '@/types';
import Debug from '@components/Debug';
import { removeBaseUrl } from '@utils/url';
import Link from 'next/link';

type Product = {
  product_type: string;
  link_text: string;
  link: WagtailPage;
};

type Props = {
  title: string;
  products: Product[];
};

function Products(props: Props) {
  const { title, products } = props;

  return (
    <div className='streamfield products'>
      <h2>{title}</h2>
      <ul className='products__list'>
        {products.map(product => (
          <li key={product.link_text}>
            <Link href={removeBaseUrl(product.link.full_url)} className='products__link'>
              <div className='products__list-item'>{product.link_text}</div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Products;
