import { WagtailDocument } from '@/types';
import FileSvg from 'icons/file.svg';
import Link from 'next/link';

type Document = {
  title: string;
  document: WagtailDocument;
};

type Props = {
  blocktext: string;
  blocktitle: string;
  documents: Document[];
};

function Downloads(props: Props) {
  const { blocktext, blocktitle, documents } = props;
  return (
    <div className='downloads streamfield'>
      <h2>{blocktitle}</h2>
      <p className='downloads__subtitle'>{blocktext}</p>
      <div className='downloads__grid'>
        {documents.map(document => (
          <Link
            key={document.document.file}
            href={document.document.file}
            download
            target='_blank'
            className='downloads__download'
          >
            <FileSvg className='downloads__download-icon-svg' />

            <div className='downloads__download-name'>
              {document.title ?? document.document.title}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Downloads;
