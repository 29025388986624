'use client';

import { Label } from '@components/Form';

import LoadingSpinner from '@components/LoadingSpinner';
import RawHtml from '@components/RawHtml';
import dynamic from 'next/dynamic';
import { useId, useMemo, useReducer, useState } from 'react';
import useSWR from 'swr';
import { Kurs, Product } from './EventFinder';
import EventRegistrationForm from './EventRegistrationForm';

import AngleRightIcon from '@icons/angle-right.svg';
import SearchIcon from '@icons/search.svg';
import { formatDate } from '@utils/formatter';
import RegistrationSuccessful from './RegistrationSuccessful';

const Dialog = dynamic(() => import('@components/Dialog'));

type State = {
  searchQuery: string | null;
  selectedCity: string | null;
};

const initialState: State = {
  searchQuery: null,
  selectedCity: null,
};

const fetcher = (url: string) => fetch(url).then(r => r.json() as Promise<Kurs[]>);

type Props = { products: Product[]; pseudoProduct: string; onSuccess?: () => void };

function OfflineCourses(props: Props) {
  const { products, pseudoProduct, onSuccess } = props;
  const id = useId();
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initialState,
  );

  // fetch data if searchQuery is set
  const { data: courses, isLoading } = useSWR<Kurs[]>(() => {
    if (!state.searchQuery) return false;

    let query = `/api/courses/?ktyp=${products.map(product => product.value.kurs).join('&ktyp=')}`;

    if (state.searchQuery) {
      query = `/api/courses/?city=${state.searchQuery}&distance=${
        process.env.NEXT_PUBLIC_SEARCH_DISTANCE ?? 50
      }&ktyp=${products.map(product => product.value.kurs).join('&ktyp=')}`;
    }

    return query;
  }, fetcher);

  // const coursesHaveCity = useMemo(
  //   () =>
  //     courses?.some(
  //       course =>
  //         course.ausbildungsort.name?.toLowerCase().replace('\r\n', '') ===
  //         state.searchQuery?.toLowerCase(),
  //       // || course.ausbildungsort.ort?.toLowerCase() === state.searchQuery?.toLowerCase(),
  //     ),
  //
  //   [courses, state.searchQuery],
  // );

  const events = useMemo(() => {
    const data: { [key: string]: Kurs[] } = {};
    // const selected = state.searchQuery;

    // ?.filter(
    //   item =>
    //     item.ausbildungsort.name?.replace('\r\n', '') === selected ||
    //     selected?.match(/[\d]{4,5}/),
    //   // || item.ausbildungsort.ort === selected,
    // )
    courses?.forEach(item => {
      if (!item.ktyp) return;

      const key = item.oid_ktyp;

      if (!data[key]) {
        data[key] = [];
      }

      data[key].push(item);
    });

    return data;
  }, [state.searchQuery, courses]);

  return (
    <>
      <form
        className='event-finder__location-search'
        onSubmit={async e => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const searchTerm = formData.get('search');

          dispatch({ searchQuery: searchTerm?.toString(), selectedCity: null });
        }}
      >
        <Label htmlFor={`${id}-search`} text='Standort suchen' className='sr-only' />
        <input
          id={`${id}-search`}
          name='search'
          type='search'
          placeholder='Wohnort oder PLZ eingeben'
        />
        <button type='submit' aria-label='Suche abschicken'>
          <SearchIcon />
        </button>
      </form>
      {isLoading ? <LoadingSpinner /> : null}
      {state.searchQuery && !isLoading && !Object.keys(events ?? {}).length ? (
        <p className='event-finder__text'>
          Für diese Suchanfrage sind leider keine Termine vorhanden.
        </p>
      ) : null}
      {Object.keys(events ?? {}).length ? (
        <div className='event-finder__events'>
          {Object.entries(events).map(([key, value]) => {
            const product = products.find(p => p.value.kurs === key);

            return (
              <div key={key} className='event-finder__event'>
                <h3 className='event-finder__event-title'>{product?.value.title}</h3>
                <RawHtml
                  className='event-finder__event-description'
                  html={product?.value.description}
                />
                {value?.slice(0, product?.value?.number_dates_to_emit)?.map(c => (
                  <Dialog
                    // eslint-disable-next-line no-underscore-dangle
                    key={c._oid}
                    triggerText={
                      <>
                        <span>
                          {c.von ? <span>Kursstart: {formatDate(c.von)}</span> : null}
                          <span>Kursform: {c.vollzeitteilzeit}</span>
                          <span>Ausbildungsort: {c?.ausbildungsort?.name}</span>
                        </span>
                        <span>
                          <AngleRightIcon />
                        </span>
                      </>
                    }
                    triggerClassName='event-finder__event-registration-button'
                    title='Bitte senden Sie mir weitere Informationen und die Anmeldung zu!'
                  >
                    <EventRegistrationForm
                      title={product?.value.title}
                      description={c.teilnehmerhinweise}
                      pseudoProduct={pseudoProduct}
                      courseType={value[0].oid_ktyp}
                      // eslint-disable-next-line no-underscore-dangle
                      course={c._oid}
                      onSuccess={onSuccess}
                    />
                  </Dialog>
                ))}
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}

export default OfflineCourses;
