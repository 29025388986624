import { Streamfield } from '@/types';
import Streamfields from '@streamfields/Streamfields';
import React from 'react';

type Props = {
  content: Streamfield[];
};

function TextBlockSnippet(props: Props) {
  const { content } = props;

  return <Streamfields fields={content} />;
}

export default TextBlockSnippet;
