import RawHtml from '@components/RawHtml';
import React from 'react';

type Props = {
  selected_product: string;
  selected_field: string;
  override?: string;
};

async function KursTypField(props: Props) {
  const { selected_field: selectedField, selected_product: selectedProduct, override } = props;

  const res = await fetch(
    `${
      process.env.WAGTAIL_API_URL ?? 'https://sbh-datenbank.werkbank.de/api'
    }/kurstyp/${selectedProduct}/`,
    {
      headers: { Accept: 'application/json' },
    },
  );
  const data = (await res.json()) as Record<string, string>;

  return (
    <div className='streamfield kurstypfield'>
      <RawHtml html={data?.[selectedField] ?? override} />
    </div>
  );
}

export default KursTypField;
