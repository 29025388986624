import { WagtailImage } from '@/types';
import RawHtml from '@components/RawHtml';
import Image from '@components/Image';
import React from 'react';

type Props = {
  title: string;
  summary: [
    {
      description: string;
      image: WagtailImage;
    },
  ];
};

function CourseSummary(props: Props) {
  const { title, summary } = props;

  return (
    <div className='streamfield course-summary'>
      <div className='container'>
        <h2>{title}</h2>
        <div className='course-summary__grid'>
          {summary.map(item => (
            <div className='course-summary__info' key={item.description}>
              <div className='course-summary__info-image-container'>
                <Image
                  image={item.image}
                  width={70}
                  height={70}
                  sizes='100vw'
                  style={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
              </div>
              <div className='course-summary__info-description'>
                <RawHtml html={item.description} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CourseSummary;
